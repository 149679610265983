<template>
  <div class="ListBox">
    <Space :height="verticalGap"></Space>
    <div class="dfaic pd10">
      <img
        :src="icon"
        alt=""
        class="mr30"
        :width="$pxToPxRatio(iconSize)"
        :height="$pxToPxRatio(iconSize)"
        v-show="icon"
      />
      <div class="flexAuto van-ellipsis">
        <TextBox :color="titleColor" class="flexAuto van-ellipsis">{{ title }}</TextBox>
        <Space :height="10"></Space>
        <TextBox color="intro" v-show="intro" :font-size="22">{{ intro }}</TextBox>
      </div>
      <TextBox :color="tipsColor" class="mr20" v-show="tips">{{ tips }}</TextBox>
      <van-icon name="arrow" color="#CACACA" :style="{ visibility: rightIcon ? '' : 'hidden' }" />
    </div>
    <Space :height="verticalGap"></Space>
    <van-divider />
  </div>
</template>
<script>
export default {
  name: 'ListBox',
  // mixins: [],
  // components: {},
  props: {
    icon: {
      default: '',
    },
    iconSize: {
      type: Number,
      default: 30,
    },
    title: {
      type: String,
      default: '',
      require: true,
    },
    titleColor: {
      type: String,
      default: 'title',
    },
    tips: {
      type: String,
      default: '',
    },
    tipsColor: {
      type: String,
      default: 'intro',
    },
    intro: {
      type: String,
      default: '',
    },
    rightIcon: {
      type: Boolean,
      default: false,
    },
    verticalGap: {
      type: Number,
      default: 34,
    },
  },
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  // methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.ListBox {
}
</style>
