import router from './init';
import axios from 'axios';
import { $localStorage } from '@/utils/common';

router.beforeEach((to, from, next) => {
  // 路由跳转时 取消所有旧请求
  axios.prototype.axiosPromiseArr.forEach((ele, index) => {
    ele.cancel();
    delete axios.prototype.axiosPromiseArr[index];
  });
  let token = $localStorage.getItem('token');
  if (token) {
    // 登陆后不可访问的地址列表
    // to.meta.loginAfterAuth 在路由中配置
    if (to.matched.some(record => record.meta.loginAfterAuth)) {
      next('/');
    }
  } else {
    // 未登录不可访问 地址和模块列表
    // to.meta.requireAuth 在路由中配置
    if (to.matched.some(record => record.meta.requireAuth)) {
      if (from.matched.some(record => record.path === '/account')) {
        // 如果当前是登录页 则跳转首页
        next('/');
      } else {
        next({
          path: '/account/login',
          query: {
            // 登录后跳转回来源页
            redirect: from.fullPath,
          },
        });
      }
    }
  }

  next();
});

router.afterEach((to, from, next) => {});
