<template>
  <div class="selectCountryBox">
    <van-popup v-model="showCountryPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showCountryPicker = false" @confirm="onConfirmCountry" />
    </van-popup>
  </div>
</template>
<script>
import country from '@/utils/choose';
export default {
  name: 'SelectCountry',
  // mixins: [],
  // components: {},
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showCountryPicker: false,
    };
  },
  // created () {},
  // mounted () {},
  methods: {
    init() {
      this.showCountryPicker = true;
    },
    onConfirmCountry(value) {
      this.showCountryPicker = false;
      this.callback(this.columnsMap[value]);
    },
  },
  // watch: {},
  computed: {
    columns() {
      return country.map(item => {
        return item.zh;
      });
    },
    columnsMap() {
      let obj = {};
      country.forEach(item => {
        obj[item.zh] = item;
      });
      return obj;
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
