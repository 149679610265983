var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Card",class:{ active: _vm.active, dark: _vm.$store.state.themeDark },style:({
    padding: _vm.paddingProcess,
    width: _vm.width ? _vm.$pxToVw(_vm.width) : '100%',
    minHeight: _vm.$pxToVw(_vm.height),
    borderRadius: _vm.$pxToVw(_vm.borderRadius),
  })},[_c('div',{staticClass:"bg1",style:({
      borderRadius: _vm.$pxToVw(_vm.borderRadius),
    })}),_c('div',{staticClass:"bg2",style:({
      borderRadius: _vm.$pxToVw(_vm.borderRadius),
    })}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }