<template>
  <div id="app">
    <router-view class="page" :key="$store.state.reload" />
    <MaskBox :show.sync="tipModal" position="center" class="updateTipModal">
      <div class="cardBox">
        <div class="flex flex-col items-center">
          <img class="imgIcon" src="@/assets/images/update.png" alt="" />
          <TextBox font-size="28" bold="600" align="center"> {{ version }} 版本更新 </TextBox>
          <div class="content">
            <TextBox font-size="28" align="center" color="#666666">
              1.优化已知问题<br />
              2.提升用户体验
            </TextBox>
          </div>
          <van-button type="primary" @click.native="update">立即升级</van-button>
        </div>
      </div>
    </MaskBox>
  </div>
</template>

<script>
import { updateLocalStorageStorage } from '@/utils/common';
import debounce from 'lodash/debounce';
export default {
  name: 'App',
  data() {
    return {
      pageUpdate: false,
      tipModal: false,
      version: '',
      downloadUrl: '',
    };
  },
  // computed: {},
  watch: {
    '$store.state.checkVersionState'(res) {
      this.check(true);
    },
    $route() {
      this.$store.commit('setSubmitLoading', false);
      this.$store.commit('setBodyColor', false);
      this.$notify.clear();
      if (window.plus) {
        updateLocalStorageStorage();
      }
    },
    '$store.state.bodyBackgroundColor': {
      handler(res) {
        let body = document.querySelector('body');
        body.style.background = res ? '#F3F3F8' : 'white';
      },
      immediate: true,
    },
  },
  created() {
    this.$store.commit('setFirst', true);
    // 兼容处理老程序的邀请链接
    let hash = location.hash;
    if (hash.indexOf('#/share?code=') !== -1) {
      let code = hash.replace('#/share?code=', '');
      this.$router.replace(`/personal/invite/ir?c=${code}`);
    }
  },
  mounted() {
    document.addEventListener(
      'plusready',
      () => {
        this.check();
      },
      false,
    );
  },
  methods: {
    check: debounce(function(showToast = false) {
      let that = this;
      if (!window.plus) {
        return;
      }
      this.$http('get', '/v1/version').then(res => {
        let pageVersion = parseInt(
          res.data.pageVersion
            .split('.')
            .join('')
            .slice(0, 3),
          10,
        );
        let storagePageVersion = this.$$localStorage.getItem('pageVersion');
        if (storagePageVersion) {
          storagePageVersion = parseInt(
            storagePageVersion
              .split('.')
              .join('')
              .slice(0, 3),
            10,
          );
          if (pageVersion > storagePageVersion) {
            plus.cache.clear();
            this.$updateLocalStorageStorage(true);
            this.$$localStorage.setItem('pageVersion', pageVersion);
            if (pageVersion - storagePageVersion >= 10) {
              this.pageUpdate = true;
              this.tipModal = true;
              this.version = res.data.pageVersion;
            }
          }
        } else {
          this.$$localStorage.setItem('pageVersion', pageVersion);
        }

        let version = res.data.version;
        this.downloadUrl = res.data.url;
        plus.runtime.getProperty(plus.runtime.appid, inf => {
          let appVersionNumber = parseInt(
            inf.version
              .split('.')
              .join('')
              .slice(0, 3),
            10,
          );
          let getVersionNumber = parseInt(
            version
              .split('.')
              .join('')
              .slice(0, 3),
            10,
          );
          if (getVersionNumber > appVersionNumber) {
            that.tipModal = true;
            this.version = version;
          } else {
            if (showToast && !this.pageUpdate) {
              that.$toast('已是最新版本');
            }
          }
        });
      });
    }, 500),
    update() {
      if (this.pageUpdate) {
        plus.runtime.restart();
        return;
      }
      this.tipModal = false;
      plus.nativeUI.showWaiting('下载更新文件...');
      plus.downloader
        .createDownload(this.downloadUrl, { filename: '_doc/update/' }, (d, status) => {
          if (status === 200) {
            plus.runtime.install(
              d.filename,
              {},
              () => {
                plus.cache.clear();
                plus.nativeUI.closeWaiting();
                plus.nativeUI.alert('版本更新成功', () => {
                  plus.runtime.restart();
                });
              },
              e => {
                plus.nativeUI.closeWaiting();
                plus.nativeUI.alert('安装更新文件失败');
              },
            );
          } else {
            plus.nativeUI.alert('下载更新文件失败！');
            plus.nativeUI.closeWaiting();
          }
        })
        .start();
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100vw;
  min-height: 100vh;
  font-size: 28px;
  display: flex;
  flex-direction: column;
  > div {
    flex: auto;
    display: flex;
    flex-direction: column;
  }
}
.contentBox {
  padding: 0 30px;
  position: relative;
}
.updateTipModal {
  text-align: center;
  .imgIcon {
    width: 200px;
    height: 200px;
  }
  .Card {
    margin: 0 auto;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    background: #ebeced;
    box-shadow: 0 2px 0 0 #ffffff, inset 0 4px 2px 0 #dbdbdb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b6b6b6;
  }
  .content {
    height: 150px;
    color: #666666;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PressButton {
    margin: 0 auto;
  }
}
.cardBox {
  background: white;
  border-radius: 10px;
  padding: 50px 0;
}
</style>
