<template>
  <van-tabbar v-model="activeIndex" placeholder active-color="#1D2F70" inactive-color="#B0B0D1" @change="change">
    <van-tabbar-item v-for="(item, index) of list" :key="index">
      {{ item.title }}
      <template #icon="props">
        <img :src="item.image" alt="" v-show="props.active" />
        <img :src="item.imageActive" alt="" v-show="!props.active" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
export default {
  name: 'TabBar',
  // mixins: [],
  // components: {},
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeIndex: 0,
      list: [
        // {
        //   title: '首页',
        //   image: require('@/assets/images/tabbar/tabbar3.png'),
        //   imageActive: require('@/assets/images/tabbar/tabbar4.png'),
        //   url: '/home',
        // },
        // {
        //   title: '奖励',
        //   image: require('@/assets/images/tabbar/tabbar7.png'),
        //   imageActive: require('@/assets/images/tabbar/tabbar8.png'),
        //   url: '/award',
        // },
        // {
        //   title: '锁仓',
        //   image: require('@/assets/images/tabbar/tabbar5.png'),
        //   imageActive: require('@/assets/images/tabbar/tabbar6.png'),
        //   url: '/locked',
        // },
        // {
        //   title: '我的',
        //   image: require('@/assets/images/tabbar/tabbar1.png'),
        //   imageActive: require('@/assets/images/tabbar/tabbar2.png'),
        //   url: '/personal',
        // },
      ],
    };
  },
  created() {
    this.activeIndex = this.active;
  },
  // mounted () {},
  methods: {
    change(value) {
      this.$router.push(this.list[value].url);
    },
  },
  watch: {
    active(res) {
      this.activeIndex = res;
    },
  },
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
