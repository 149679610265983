import Vue from 'vue';
import routes from './routes';
import Router from 'vue-router';
Vue.use(Router);

// 拦截push异常
const originalPush = Router.prototype.push;
Router.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => err);
};

// 拦截replace异常
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
