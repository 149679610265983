<template>
  <div class="PriceUnit" :style="{ textAlign: align, fontSize: $pxToVw(fontSize) }">
    {{ prifix }}{{ first }}
    <i>{{ unit }}</i>
  </div>
</template>
<script>
export default {
  name: 'PriceUnit',
  // mixins: [],
  // components: {},
  props: {
    value: {
      type: Number || String,
      default: 0,
    },
    fontSize: {
      type: Number,
      default: 34,
    },
    unit: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'price',
    },
    align: {
      type: String,
      default: 'left',
    },
    prifix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  // methods: {},
  // watch: {},
  computed: {
    first() {
      let v = '';
      if (this.type === 'price') {
        v = this.$priceFilter(Number(this.value));
      }
      if (this.type === 'string') {
        v = this.value;
      }
      return v;
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.PriceUnit {
  display: flex;
  align-items: flex-end;
  color: #071c5e;
  line-height: 1;
  font-family: numberFont;
  @font-face {
    font-family: 'numberFont';
    src: url('~@/assets/DINPro-Medium.ttf') format('truetype');
  }
  i {
    font-size: 24px;
    margin-left: 10px;
    line-height: 1;
    margin-bottom: 2px;
    font-weight: initial;
  }
}
</style>
