<template>
  <div
    class="Card"
    :class="{ active: active, dark: $store.state.themeDark }"
    :style="{
      padding: paddingProcess,
      width: width ? $pxToVw(width) : '100%',
      minHeight: $pxToVw(height),
      borderRadius: $pxToVw(borderRadius),
    }"
  >
    <div
      class="bg1"
      :style="{
        borderRadius: $pxToVw(borderRadius),
      }"
    ></div>
    <div
      class="bg2"
      :style="{
        borderRadius: $pxToVw(borderRadius),
      }"
    ></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    width: {
      type: Number || String,
      default: 0,
      validator(res) {
        return Number(res).toString() === res.toString();
      },
    },
    height: {
      type: Number || String,
      default: 90,
      validator(res) {
        return Number(res).toString() === res.toString();
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: '15px',
    },
    borderRadius: {
      type: Number || String,
      default: 8,
      validator(res) {
        return Number(res).toString() === res.toString();
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    paddingProcess() {
      let arr = this.padding.split(' ');
      let arrProcess = arr.map((item, index) => {
        return this.$pxToVw(item.replace('px', ''));
      });
      return arrProcess.join(' ');
    },
  },
};
</script>

<style scoped lang="less">
.Card {
  width: 100%;
  min-height: 90px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  .bg1 {
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: linear-gradient(180deg, #f4f5f6 0%, #ffffff 100%);
    position: absolute;
    border-radius: 8px;
    z-index: -1;
  }
  .bg2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    z-index: -2;
  }
  &.active {
    .bg1 {
      background: linear-gradient(180deg, #d6e3f0 0%, #f4f6ff 100%);
    }
    .bg2 {
      background: linear-gradient(180deg, rgba(244, 246, 255, 1), rgba(218, 229, 242, 1));
      // box-shadow: 0 5px 20px 0 rgba(225, 225, 225, 0.7);
    }
  }
}
.PressButton {
  .bg2 {
    box-shadow: 0px 5px 20px 0px rgba(225, 225, 225, 0.7);
  }
}
.dark.Card {
  .bg1 {
    background: linear-gradient(360deg, #353a40 0%, #121416 100%);
  }
  .bg2 {
    background: linear-gradient(180deg, rgba(80, 89, 98, 1), rgba(24, 24, 25, 1));
    // box-shadow: 0px 5px 20px 0px #020303;
  }
}
</style>
