<template>
  <div class="DetailBox">
    <NavBar :title="title"></NavBar>
    <Space :height="30"></Space>
    <div class="contentBox">
      <div class="content">
        <slot></slot>
      </div>
    </div>
    <Space :height="30"></Space>
  </div>
</template>
<script>
export default {
  name: 'DetailBox',
  // mixins: [],
  // components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  // created () {},
  mounted() {
    this.$store.commit('setBodyColor');
  },
  // methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.content {
  width: 690px;
  background: @white;
  border-radius: 10px;
  padding: 40px 56px;
  line-height: 1.6;
}
</style>
