let devConfig = () => {
  return {
    // url: 'http://172.18.1.92:8090/api',
    url: '/api',
  };
};

// let testConfig = () => {
//   return {
//     url: '172.18.1.92:8090/api',
//   };
// };

let prodConfig = () => {
  return {
    // url: 'http://172.18.1.92:8090/api',
    url: '/api',
  };
};

let targetConfig = {};
switch (process.env.NODE_ENV) {
  case 'development':
    targetConfig = devConfig();
    break;
  // case 'testing':
  //   targetConfig = testConfig();
  //   break;
  case 'production':
    targetConfig = prodConfig();
    break;
  default:
    targetConfig = prodConfig();
    break;
}

export default targetConfig;
