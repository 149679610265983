import Vue from 'vue';
import Vuex from 'vuex';
import myAssets from '@/pages/personal/myAssets/model';
import personal from '@/pages/personal/model';

import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
});

Vue.use(Vuex);
export default new Vuex.Store({
  strict: true,
  plugins: [vuexLocal.plugin],
  modules: {
    myAssets,
    personal,
  },
  state: {
    first: true,
    submitLoading: false,
    // 页面刷新
    reload: false,
    // 小数位
    fixed: 4,
    // 显示浅色背景
    bodyBackgroundColor: false,
    userInfo: {
      phone: '',
      isSetPassWord: 0,
      cardId: '',
      cardName: '',
      regions: '',
      // 0未认证 1认证中 2已认证 3拒绝
      status: 0,
    },
    downloadAppSite: {
      android: '',
      ios: '',
    },
    checkVersionState: 0,
  },
  mutations: {
    save(state, action) {
      for (let [key, val] of Object.entries(action)) {
        state[key] = val;
      }
    },
    setFirst(state, res) {
      state.first = res;
    },
    setReload(state) {
      state.reload = !state.reload;
    },
    setSubmitLoading(state, res) {
      state.submitLoading = res;
    },
    setBodyColor(state, show = true) {
      state.bodyBackgroundColor = show;
    },
    setUserInfo(state, res) {
      state.userInfo = res;
    },
  },
  actions: {
    getUserInfo({ commit }) {
      Vue.prototype.$http('post', '/v1/user/accountInfo').then(res => {
        commit('setUserInfo', res.data);
      });
    },
  },
});
