<template>
  <div class="EmptyBox">
    <van-empty :image="require('@/assets/images/empty.png')" :description="title" class="customImage" />
  </div>
</template>
<script>
export default {
  name: 'Empty',
  // mixins: [],
  // components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  // methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
/deep/ .customImage {
  .van-empty__image {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  img {
    width: 250px;
    max-height: 250px;
  }
}
</style>
