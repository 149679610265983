import Vue from 'vue';

import * as commonAll from '@/utils/common';
for (let [name, value] of Object.entries(commonAll)) {
  Vue.prototype[`$${name}`] = value;
}

import axios from 'axios';
import { Toast } from 'vant';
import qs from 'qs';

Vue.prototype.$http = (type, url, params) => {
  if (type === 'get' && params) {
    url = `${url}?${qs.stringify(params, { arrayFormat: 'indices' })}`;
    params = {};
  }
  let config = '';
  if (type === 'file') {
    type = 'post';
    let formdata = new FormData();
    for (let [key, value] of Object.entries(params)) {
      formdata.append(key, value);
    }
    params = formdata;
    config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  }
  let response = axios[type](url, params, config);
  let thenCallback = () => {};
  let thenAllCallback = () => {};
  let catchCallback = () => {};
  let allCallback = () => {};
  response
    .then(res => {
      if (res.code === 200) {
        thenCallback(res);
      }
      if (res.code === 0) {
        Toast(res.msg);
      }
      thenAllCallback(res);
      allCallback();
    })
    .catch(res => {
      console.error(`[ catch ](${url})`, res);
      catchCallback(res);
      allCallback();
    });
  return {
    then(callback) {
      thenCallback = callback;
      return this;
    },
    thenAll(callback) {
      thenAllCallback = callback;
      return this;
    },
    catch(callback) {
      catchCallback = callback;
    },
    all(callback) {
      allCallback = callback;
    },
  };
};

import * as dayjs from 'dayjs';
Vue.prototype.$dayjs = dayjs;

Vue.prototype.$isLogin = () => {
  return !!commonAll.$localStorage.getItem('token');
};
