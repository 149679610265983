export const updateLocalStorageStorage = () => {
  if (localStorage.getItem('token') && !plus.storage.getItem('token')) {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let value = localStorage.getItem(key);
      if (value) {
        plus.storage.setItem(key, value.toString());
      }
    }
  }
  if (plus.storage.getItem('token')) {
    for (let i = 0; i < plus.storage.getLength(); i++) {
      let key = plus.storage.key(i);
      let value = plus.storage.getItem(key);
      if (value) {
        localStorage.setItem(key, value);
      }
    }
  }
};

export const $localStorage = {
  setItem: (key, value) => {
    localStorage.setItem(key, value);
    window.plus && plus.storage.setItem(key, value.toString());
  },
  getItem: key => {
    let res = '';
    res = localStorage.getItem(key);
    if ((res === '' || res === null) && window.plus) {
      res = plus.storage.getItem(key);
    }
    return res;
  },
  removeItem: key => {
    localStorage.removeItem(key);
    window.plus && plus.storage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
    window.plus && plus.storage.clear();
  },
};

export const logout = () => {
  // $localStorage.removeItem('token');
  // $localStorage.removeItem('tokenExpireTime');
  $localStorage.clear();
};

/**
 * @description 750原型的px转换为vw
 * @author hys
 * @date 2020-02-04
 * @param {number} px
 * @returns {string}
 */
export const pxToVw = px => {
  const ratio = 750 / 100;
  const vw = `${(px / ratio).toFixed(6)}vw`;
  return vw;
};

/**
 * @description 750原型的px转换为显示屏幕的px
 * @param {number} px
 * @returns {number}
 */
export const pxToPxRatio = px => {
  const clientWidth = document.body.clientWidth;
  const pxRatio = px * (clientWidth / 750);
  return pxRatio;
};

/**
 * @description 显示原型的px转换为vw
 * @author hys
 * @date 2020-02-13
 * @param {number} px
 * @returns {string}
 */
export const pxToVwRatio = px => {
  const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
  const ratio = clientWidth / 100;
  const vw = `${(px / ratio).toFixed(6)}vw`;
  return vw;
};

// 保留两位小数以及千分位的分隔符
export const priceFilter = (num, scale = 4, replenish = false) => {
  let str = toFixed(num, scale, replenish).toString();
  let res = str || '0';
  // 取小数部分
  let dot = '';
  const find = str.indexOf('.');
  if (find !== -1) {
    res = str.substring(0, find);
    dot = str.substring(str.length, find);
  }
  // 取到整数部分
  const intSum = res.replace(/\B(?=(?:\d{3})+$)/g, ',');
  const ret = intSum + dot;
  return ret;
};

// 处理数字小数位
// eslint-disable-next-line max-params
export const toFixed = (number, scale = 4, replenish = false, roundOff = true) => {
  let res = '';
  if (number) {
    let str = `${number}`;
    if (str.indexOf('e') > -1 || str.indexOf('E') > -1) {
      // 科学计数法
      let str = number.toFixed(scale + 1);
      res = str.substring(0, str.length - 1);
    } else if (str.indexOf('.') > -1) {
      // 小数
      if (scale === 0) {
        res = str.substring(0, str.indexOf('.'));
      } else {
        if (roundOff) {
          let resArr = res.toString().split('.');
          if (resArr[1]) {
            // 截取指定位数
            res = str.substring(0, str.indexOf('.') + scale + 1 + 1);
            // 增加四舍五入功能
            res = accDiv(Math.round(accMul(Number(res), Math.pow(10, scale))), Math.pow(10, scale)).toString();
          } else {
            // 截取指定位数
            res = str.substring(0, str.indexOf('.') + scale + 1);
          }
        } else {
          // 截取指定位数
          res = str.substring(0, str.indexOf('.') + scale + 1);
        }
      }
    } else {
      // 整数
      res = str;
    }
  }
  // 是否填充0
  if (replenish) {
    res = res || '0';
    let resArr = res.toString().split('.');
    if (resArr[1]) {
      let diff = scale - resArr[1].length;
      if (diff > 0) {
        let a = [];
        a.length = diff;
        a.fill(0);
        let pushStr = a.join('');
        res = res + pushStr;
      }
    } else {
      if (Number(scale)) {
        let a = [];
        a.length = scale;
        a.fill(0);
        let pushStr = a.join('');
        res = `${res}.${pushStr}`;
      }
    }
  }

  return res;
};

/**
 * 除法函数，用来得到精确的除法结果<br>
 * javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 *
 * @param {number} arg1
 * @param {number} arg2
 * @returns {number} arg1除以arg2的精确结果
 * @example
 *
 * accDiv(0.2, 0.3)
 * // => 0.6666666666666666
 */
export function accDiv(arg1, arg2) {
  let t1 = 0;
  let t2 = 0;
  let r1;
  let r2;

  try {
    t1 = arg1.toString().split('.')[1].length;
  } catch (e) {}
  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {}
  r1 = Number(arg1.toString().replace('.', ''));
  r2 = Number(arg2.toString().replace('.', ''));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

/**
 * 手机号码中间部分替换成指定符号
 *
 * @param {string} phone
 * @param {string} symbol 默认为`*`
 * @returns {string|*|XML|void}
 * @example
 *
 * formatPhone('15858264903');
 * // => 158****4903
 */
export const formatPhone = (phone, symbol) => {
  if (!symbol) symbol = '****';
  return phone.toString().replace(/(\d{3})\d{4}(\d+)/, `$1${symbol}$2`);
};

// 是否为有效的密码(6-16位字母加数字组合，不能包含空格)
export const isValidPassword = val => {
  const reg = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6,16}$/;
  return reg.test(val);
};

export const judgePhone = () => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1;
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isAndroid) {
    return 'A';
  }
  if (isIOS) {
    return 'S';
  }
  return false;
};

/**
 * 乘法函数，用来得到精确的乘法结果<br>
 * javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 *
 * @param {number} arg1
 * @param {number} arg2
 * @returns {number} arg1乘以arg2的精确结果
 * @example
 *
 * accMul(0.222, 0.3333)
 * // => 0.0739926
 */
export const accMul = (arg1 = 0, arg2 = 0) => {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();

  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
};

export const toNumber = num => {
  // 处理非数字
  if (isNaN(num)) return num;

  // 处理不需要转换的数字
  if (!/e/i.test(num.toString())) return num;

  return num.toFixed(18).replace(/\.?0+$/, '');
};

// 判断微信
export const isWeiXin = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  let a = ua.match(/MicroMessenger/i);
  if (a && a[0] === 'micromessenger') {
    return true;
  } else {
    return false;
  }
};

// 判断QQ
export const isQQ = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf(' qq') > -1 && ua.indexOf('mqqbrowser') < 0) {
    return true;
  } else {
    return false;
  }
};
