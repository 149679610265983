import debounceInit from 'lodash/debounce';
import throttle from 'lodash/throttle';
import cloneDeep from 'lodash/cloneDeep';

// 自定义配置防抖
let debounce = (func, wait = 1000, first = true) => {
  let config = {};
  if (first) {
    config = {
      leading: true,
      trailing: false,
    };
  }
  return debounceInit(func, wait, config);
};

window._ = {
  debounce,
  throttle,
  cloneDeep,
};
