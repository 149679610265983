<template>
  <div class="TranslateCoinBox">
    <div class="translateBox">
      <div class="left">
        <div class="lineBox van-hairline--bottom">
          <div class="innerBox">
            <img src="@/assets/images/locked/translate2.png" alt="" class="icon" />
            <span>从</span>
            <div class="target">{{ start && start.name }}</div>
            <!-- <van-popover
              class="target"
              :class="switchAnimation ? 'psd' : ''"
              v-model="showPopover1"
              trigger="click"
              :actions="actions"
              @select="action => onSelect(action, 'startId')"
              placement="bottom-start"
            >
              <template #reference>
                <div class="df aic">
                  <div>{{ start && start.name }}</div>
                  <Space :width="20"></Space>
                  <van-icon color="#CACACA" name="arrow" />
                </div>
              </template>
            </van-popover> -->
          </div>
        </div>
        <div class="lineBox">
          <div class="innerBox">
            <img src="@/assets/images/locked/translate1.png" alt="" class="icon" />
            <span>至</span>
            <van-popover
              class="target"
              :class="switchAnimation ? 'psu' : ''"
              v-model="showPopover2"
              trigger="click"
              :actions="actions"
              @select="action => onSelect(action, 'endId')"
              placement="bottom-start"
            >
              <template #reference>
                <div class="df aic">
                  <div>{{ end && end.name }}</div>
                  <Space :width="20"></Space>
                  <van-icon color="#CACACA" name="arrow" />
                </div>
              </template>
            </van-popover>
          </div>
        </div>
      </div>
      <!-- <img src="@/assets/images/locked/translate.png" alt="" class="right" @click="switchTranslate" /> -->
      <img src="@/assets/images/locked/translate.png" alt="" class="right" />
    </div>
    <Space :height="30"></Space>
    <div class="inputBox">
      <TextBox color="textColor1" class="titleBox">转账数量</TextBox>
      <Space :height="26"></Space>
      <van-field
        type="number"
        placeholder="请输入划转数量"
        class="bg radius10"
        :formatter="res => $toFixed(res, coin.xiaoShuDian)"
        v-model="input"
      >
        <template #button>
          <TextBox color="title" @click.native="showTargetCoinPicker = true">{{ coin.name }}</TextBox>
          <van-popup v-model="showTargetCoinPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="coinList.map(item => item.name)"
              @cancel="showTargetCoinPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
        </template>
      </van-field>
      <Space :height="31"></Space>
      <div class="dfjcsbaic balanceBox">
        <TextBox color="textColor4" :font-size="24"
          >可用：{{ $toFixed(coinDetail.available, coinDetail.xiaoShuDian, true) }} {{ coin.name }}</TextBox
        >
        <TextBox color="textColor2" style="text-decoration: underline;" @click.native="allTranslate">
          全部划转
        </TextBox>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TranslateCoin',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      showTargetCoinPicker: false,
      coinActive: 0,
      coinList: [],
      input: '',
      coinDetail: {
        available: '',
        balance: '',
        coinId: '',
        coinName: '',
        frozen: '',
        isDeposit: '',
        isWithdraw: '',
        userId: '',
        walletAccountId: '',
        xiaoShuDian: '',
      },
      // id
      startId: 2,
      // id
      endId: 1,
      switchAnimation: false,
      list: [],
      showPopover1: false,
      actions: [],
      showPopover2: false,
    };
  },
  created() {
    this.getCoinList();
    this.endId = this.$store.state.personal.userActive === 0 ? 1 : 3;
  },
  // mounted() {},
  methods: {
    onSelect(action, target) {
      this[target] = action.id;
      this.actions = [];
      this.actions = this.list
        .map((item, index) => {
          if (item.id === this.startId) return '';
          if (item.id === this.endId) return '';
          return item;
        })
        .filter(item => item);
      let watchTarget = this.switchAnimation ? 'endId' : 'startId';
      if (target === watchTarget) {
        setTimeout(() => {
          this.reset();
          this.getUserAssets();
        }, 300);
      }
    },
    init(res) {
      this.switchAnimation = false;
      this.getCoinList();
    },
    reset() {
      this.input = '';
    },
    onConfirm(value, index) {
      this.coinActive = index;
      this.showTargetCoinPicker = false;
      this.getUserAssets();
    },
    getCoinList() {
      this.$http('get', '/v1/assets/coinInfo').then(res => {
        this.coinList = res.data;
        res.data.forEach((item, index) => {
          if (Number(item.id) === Number(this.$route.query.coinId)) {
            this.coinActive = index;
          }
        });
        this.getAccountInfo();
      });
    },
    getAccountInfo() {
      // 获取钱包类型
      this.$http('get', '/v1/assets/walletAccountInfo').then(res => {
        res.data = res.data.map((item, index) => {
          item.index = index;
          return item;
        });
        this.list = res.data.map(item => {
          item.text = item.name;
          return item;
        });
        this.actions = res.data
          .map((item, index) => {
            if (item.id === this.startId) return '';
            if (item.id === this.endId) return '';
            item.text = item.name;
            return item;
          })
          .filter(item => item);
        this.getUserAssets();
      });
    },
    getUserAssets() {
      this.$http('post', '/v1/user/userAssetsInfo', {
        coinId: this.coin.id,
        walletAccountId: !this.switchAnimation ? this.start.id : this.end.id,
      }).then(res => {
        this.coinDetail = res.data;
      });
    },
    allTranslate() {
      this.input = this.coinDetail.available;
    },
    switchTranslate: _.throttle(function() {
      this.switchAnimation = !this.switchAnimation;
      setTimeout(() => {
        this.reset();
        this.getUserAssets();
      }, 300);
    }, 500),
    submit(callback = () => {}) {
      if (this.input > this.coinDetail.available) {
        this.$toast('币种可用余额不足');
        return;
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/assets/assetsTransfer', {
        amount: this.input,
        coinId: this.coinDetail.coinId,
        walletAccountIdFrom: !this.switchAnimation ? this.start.id : this.end.id,
        walletAccountIdTo: !this.switchAnimation ? this.end.id : this.start.id,
      })
        .then(res => {
          this.$toast('划转成功');
          this.reset();
          this.getUserAssets();
          callback();
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    },
  },
  // watch: {},
  computed: {
    start() {
      return this.list.filter(item => {
        return item.id === this.startId;
      })[0];
    },
    end() {
      return this.list.filter(item => {
        return item.id === this.endId;
      })[0];
    },
    coin() {
      return (
        this.coinList[this.coinActive] || {
          alias: '',
          bgColor: '',
          bgUrl: '',
          chineseName: '',
          fee: 0,
          iconUrl: '',
          id: 0,
          isDeposit: 0,
          isWithdraw: 0,
          name: '',
          remark: '',
          sort: 0,
          withdrawMin: 0,
          xiaoShuDian: 0,
        }
      );
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.translateBox {
  display: flex;
  background: @white;
  border-radius: 10px;
  padding: 26px 8px;
  .psd {
    position: relative;
    transform: translateY(84px);
  }
  .psu {
    position: relative;
    transform: translateY(-85px);
  }
  .left {
    flex: auto;
    flex-direction: column;
    display: flex;
    .lineBox {
      flex: 1;
      display: flex;
      align-items: flex-start;
      &:last-child {
        align-items: flex-end;
      }
      .innerBox {
        flex: auto;
        display: flex;
        align-items: center;
        margin-left: 17px;
        /deep/ .van-popover__wrapper {
          flex: auto;
        }
      }
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 25px;
      }
      span {
        margin-right: 32px;
        color: @intro-color;
      }
      .target {
        color: @title-color;
        transition: all 0.3s;
      }
    }
  }
  .right {
    width: 122px;
    height: 122px;
    flex-shrink: 0;
  }
}
.inputBox {
  padding: 22px 23px 25px;
  background: @white;
  border-radius: 10px;
}
</style>
