export default [
  { shoupinyin: 'Z', en: 'China', zh: '中国', locale: 'CN', code: 86 },
  { shoupinyin: 'M', en: 'UnitedStatesofAmerica', zh: '美国', locale: 'US', code: 1 },
  { shoupinyin: 'H', en: 'Korea', zh: '韩国', locale: 'KR', code: 82 },
  { shoupinyin: 'T', en: 'Thailand', zh: '泰国', locale: 'TH', code: 66 },
  { shoupinyin: 'A', en: 'Australia', zh: '澳大利亚', locale: 'AU', code: 61 },
  { shoupinyin: 'M', en: 'Burma', zh: '缅甸', locale: 'MM', code: 95 },
  { shoupinyin: 'F', en: 'France', zh: '法国', locale: 'FR', code: 33 },
  { shoupinyin: 'J', en: 'Kampuchea(Cambodia)', zh: '柬埔寨', locale: 'KH', code: 855 },
  { shoupinyin: 'L', en: 'Laos', zh: '老挝', locale: 'LA', code: 856 },
  // {shoupinyin: "A", en: "Angola",zh: "A",locale: "AO", },
  { shoupinyin: 'A', en: 'Angola', zh: '安哥拉', locale: 'AO', code: 244 },
  { shoupinyin: 'A', en: 'Afghanistan', zh: '阿富汗', locale: 'AF', code: 93 },
  { shoupinyin: 'A', en: 'Albania', zh: '阿尔巴尼亚', locale: 'AL', code: 355 },
  { shoupinyin: 'A', en: 'Algeria', zh: '阿尔及利亚', locale: 'DZ', code: 213 },
  { shoupinyin: 'A', en: 'Andorra', zh: '安道尔共和国', locale: 'AD', code: 376 },
  { shoupinyin: 'A', en: 'Anguilla', zh: '安圭拉岛', locale: 'AI', code: 1264 },
  { shoupinyin: 'A', en: 'AntiguaandBarbuda', zh: '安提瓜和巴布达', locale: 'AG', code: 1268 },
  { shoupinyin: 'A', en: 'Argentina', zh: '阿根廷', locale: 'AR', code: 54 },
  { shoupinyin: 'Y', en: 'Armenia', zh: '亚美尼亚', locale: 'AM', code: 374 },
  { shoupinyin: 'A', en: 'Austria', zh: '奥地利', locale: 'AT', code: 43 },
  { shoupinyin: 'A', en: 'Azerbaijan', zh: '阿塞拜疆', locale: 'AZ', code: 994 },
  { shoupinyin: 'B', en: 'Bahamas', zh: '巴哈马', locale: 'BS', code: 1242 },
  { shoupinyin: 'B', en: 'Bahrain', zh: '巴林', locale: 'BH', code: 973 },
  { shoupinyin: 'M', en: 'Bangladesh', zh: '孟加拉国', locale: 'BD', code: 880 },
  { shoupinyin: 'B', en: 'Barbados', zh: '巴巴多斯', locale: 'BB', code: 1246 },
  { shoupinyin: 'B', en: 'Belarus', zh: '白俄罗斯', locale: 'BY', code: 375 },
  { shoupinyin: 'B', en: 'Belgium', zh: '比利时', locale: 'BE', code: 32 },
  { shoupinyin: 'B', en: 'Belize', zh: '伯利兹', locale: 'BZ', code: 501 },
  { shoupinyin: 'B', en: 'Benin', zh: '贝宁', locale: 'BJ', code: 229 },
  { shoupinyin: 'B', en: 'BermudaIs.', zh: '百慕大群岛', locale: 'BM', code: 1441 },
  { shoupinyin: 'B', en: 'Bolivia', zh: '玻利维亚', locale: 'BO', code: 591 },
  { shoupinyin: 'B', en: 'Botswana', zh: '博茨瓦纳', locale: 'BW', code: 267 },
  { shoupinyin: 'B', en: 'Brazil', zh: '巴西', locale: 'BR', code: 55 },
  { shoupinyin: 'W', en: 'Brunei', zh: '文莱', locale: 'BN', code: 673 },
  { shoupinyin: 'B', en: 'Bulgaria', zh: '保加利亚', locale: 'BG', code: 359 },
  { shoupinyin: 'B', en: 'Burkina-faso', zh: '布基纳法索', locale: 'BF', code: 226 },
  { shoupinyin: 'B', en: 'Burundi', zh: '布隆迪', locale: 'BI', code: 257 },
  { shoupinyin: 'K', en: 'Cameroon', zh: '喀麦隆', locale: 'CM', code: 237 },
  { shoupinyin: 'J', en: 'Canada', zh: '加拿大', locale: 'CA', code: 1 },
  { shoupinyin: 'Z', en: 'CentralAfricanRepublic', zh: '中非共和国', locale: 'CF', code: 236 },
  { shoupinyin: 'Z', en: 'Chad', zh: '乍得', locale: 'TD', code: 235 },
  { shoupinyin: 'Z', en: 'Chile', zh: '智利', locale: 'CL', code: 56 },
  { shoupinyin: 'G', en: 'Colombia', zh: '哥伦比亚', locale: 'CO', code: 57 },
  { shoupinyin: 'G', en: 'Congo', zh: '刚果', locale: 'CG', code: 242 },
  { shoupinyin: 'K', en: 'CookIs.', zh: '库克群岛', locale: 'CK', code: 682 },
  { shoupinyin: 'G', en: 'CostaRica', zh: '哥斯达黎加', locale: 'CR', code: 506 },
  { shoupinyin: 'G', en: 'Cuba', zh: '古巴', locale: 'CU', code: 53 },
  { shoupinyin: 'Z', en: 'Cyprus', zh: '塞浦路斯', locale: 'CY', code: 357 },
  { shoupinyin: 'J', en: 'CzechRepublic', zh: '捷克', locale: 'CZ', code: 420 },
  { shoupinyin: 'D', en: 'Denmark', zh: '丹麦', locale: 'DK', code: 45 },
  { shoupinyin: 'J', en: 'Djibouti', zh: '吉布提', locale: 'DJ', code: 253 },
  { shoupinyin: 'D', en: 'DominicaRep.', zh: '多米尼加共和国', locale: 'DO', code: 1890 },
  { shoupinyin: 'E', en: 'Ecuador', zh: '厄瓜多尔', locale: 'EC', code: 593 },
  { shoupinyin: 'A', en: 'Egypt', zh: '埃及', locale: 'EG', code: 20 },
  { shoupinyin: 'S', en: 'EISalvador', zh: '萨尔瓦多', locale: 'SV', code: 503 },
  { shoupinyin: 'A', en: 'Estonia', zh: '爱沙尼亚', locale: 'EE', code: 372 },
  { shoupinyin: 'A', en: 'Ethiopia', zh: '埃塞俄比亚', locale: 'ET', code: 251 },
  { shoupinyin: 'F', en: 'Fiji', zh: '斐济', locale: 'FJ', code: 679 },
  { shoupinyin: 'F', en: 'Finland', zh: '芬兰', locale: 'FI', code: 358 },
  { shoupinyin: 'F', en: 'FrenchGuiana', zh: '法属圭亚那', locale: 'GF', code: 594 },
  { shoupinyin: 'J', en: 'Gabon', zh: '加蓬', locale: 'GA', code: 241 },
  { shoupinyin: 'G', en: 'Gambia', zh: '冈比亚', locale: 'GM', code: 220 },
  { shoupinyin: 'G', en: 'Georgia', zh: '格鲁吉亚', locale: 'GE', code: 995 },
  { shoupinyin: 'D', en: 'Germany', zh: '德国', locale: 'DE', code: 49 },
  { shoupinyin: 'J', en: 'Ghana', zh: '加纳', locale: 'GH', code: 233 },
  { shoupinyin: 'Z', en: 'Gibraltar', zh: '直布罗陀', locale: 'GI', code: 350 },
  { shoupinyin: 'X', en: 'Greece', zh: '希腊', locale: 'GR', code: 30 },
  { shoupinyin: 'G', en: 'Grenada', zh: '格林纳达', locale: 'GD', code: 1809 },
  { shoupinyin: 'G', en: 'Guam', zh: '关岛', locale: 'GU', code: 1671 },
  { shoupinyin: 'W', en: 'Guatemala', zh: '危地马拉', locale: 'GT', code: 502 },
  { shoupinyin: 'J', en: 'Guinea', zh: '几内亚', locale: 'GN', code: 224 },
  { shoupinyin: 'G', en: 'Guyana', zh: '圭亚那', locale: 'GY', code: 592 },
  { shoupinyin: 'H', en: 'Haiti', zh: '海地', locale: 'HT', code: 509 },
  { shoupinyin: 'H', en: 'Honduras', zh: '洪都拉斯', locale: 'HN', code: 504 },
  { shoupinyin: 'X', en: 'Hongkong', zh: '香港', locale: 'HK', code: 852 },
  { shoupinyin: 'X', en: 'Hungary', zh: '匈牙利', locale: 'HU', code: 36 },
  { shoupinyin: 'B', en: 'Iceland', zh: '冰岛', locale: 'IS', code: 354 },
  { shoupinyin: 'Y', en: 'India', zh: '印度', locale: 'IN', code: 91 },
  { shoupinyin: 'Y', en: 'Indonesia', zh: '印度尼西亚', locale: 'ID', code: 62 },
  { shoupinyin: 'Y', en: 'Iran', zh: '伊朗', locale: 'IR', code: 98 },
  { shoupinyin: 'Y', en: 'Iraq', zh: '伊拉克', locale: 'IQ', code: 964 },
  { shoupinyin: 'A', en: 'Ireland', zh: '爱尔兰', locale: 'IE', code: 353 },
  { shoupinyin: 'Y', en: 'Israel', zh: '以色列', locale: 'IL', code: 972 },
  { shoupinyin: 'Y', en: 'Italy', zh: '意大利', locale: 'IT', code: 39 },
  { shoupinyin: 'Y', en: 'Jamaica', zh: '牙买加', locale: 'JM', code: 1876 },
  { shoupinyin: 'R', en: 'Japan', zh: '日本', locale: 'JP', code: 81 },
  { shoupinyin: 'Y', en: 'Jordan', zh: '约旦', locale: 'JO', code: 962 },
  { shoupinyin: 'H', en: 'Kazakstan', zh: '哈萨克斯坦', locale: 'KZ', code: 327 },
  { shoupinyin: 'K', en: 'Kenya', zh: '肯尼亚', locale: 'KE', code: 254 },
  { shoupinyin: 'K', en: 'Kuwait', zh: '科威特', locale: 'KW', code: 965 },
  { shoupinyin: 'J', en: 'Kyrgyzstan', zh: '吉尔吉斯坦', locale: 'KG', code: 331 },
  { shoupinyin: 'L', en: 'Latvia', zh: '拉脱维亚', locale: 'LV', code: 371 },
  { shoupinyin: 'L', en: 'Lebanon', zh: '黎巴嫩', locale: 'LB', code: 961 },
  { shoupinyin: 'L', en: 'Lesotho', zh: '莱索托', locale: 'LS', code: 266 },
  { shoupinyin: 'L', en: 'Liberia', zh: '利比里亚', locale: 'LR', code: 231 },
  { shoupinyin: 'L', en: 'Libya', zh: '利比亚', locale: 'LY', code: 218 },
  { shoupinyin: 'L', en: 'Liechtenstein', zh: '列支敦士登', locale: 'LI', code: 423 },
  { shoupinyin: 'L', en: 'Lithuania', zh: '立陶宛', locale: 'LT', code: 370 },
  { shoupinyin: 'L', en: 'Luxembourg', zh: '卢森堡', locale: 'LU', code: 352 },
  { shoupinyin: 'A', en: 'Macao', zh: '澳门', locale: 'MO', code: 853 },
  { shoupinyin: 'M', en: 'Madagascar', zh: '马达加斯加', locale: 'MG', code: 261 },
  { shoupinyin: 'M', en: 'Malawi', zh: '马拉维', locale: 'MW', code: 265 },
  { shoupinyin: 'M', en: 'Malaysia', zh: '马来西亚', locale: 'MY', code: 60 },
  { shoupinyin: 'M', en: 'Maldives', zh: '马尔代夫', locale: 'MV', code: 960 },
  { shoupinyin: 'M', en: 'Mali', zh: '马里', locale: 'ML', code: 223 },
  { shoupinyin: 'M', en: 'Malta', zh: '马耳他', locale: 'MT', code: 356 },
  { shoupinyin: 'M', en: 'Mauritius', zh: '毛里求斯', locale: 'MU', code: 230 },
  { shoupinyin: 'M', en: 'Mexico', zh: '墨西哥', locale: 'MX', code: 52 },
  { shoupinyin: 'M', en: 'Moldova,Republicof', zh: '摩尔多瓦', locale: 'MD', code: 373 },
  { shoupinyin: 'M', en: 'Monaco', zh: '摩纳哥', locale: 'MC', code: 377 },
  { shoupinyin: 'M', en: 'Mongolia', zh: '蒙古', locale: 'MN', code: 976 },
  { shoupinyin: 'M', en: 'MontserratIs', zh: '蒙特塞拉特岛', locale: 'MS', code: 1664 },
  { shoupinyin: 'M', en: 'Morocco', zh: '摩洛哥', locale: 'MA', code: 212 },
  { shoupinyin: 'M', en: 'Mozambique', zh: '莫桑比克', locale: 'MZ', code: 258 },
  { shoupinyin: 'N', en: 'Namibia', zh: '纳米比亚', locale: 'NA', code: 264 },
  { shoupinyin: 'L', en: 'Nauru', zh: '瑙鲁', locale: 'NR', code: 674 },
  { shoupinyin: 'N', en: 'Nepal', zh: '尼泊尔', locale: 'NP', code: 977 },
  { shoupinyin: 'H', en: 'Netherlands', zh: '荷兰', locale: 'NL', code: 31 },
  { shoupinyin: 'X', en: 'NewZealand', zh: '新西兰', locale: 'NZ', code: 64 },
  { shoupinyin: 'N', en: 'Nicaragua', zh: '尼加拉瓜', locale: 'NI', code: 505 },
  { shoupinyin: 'N', en: 'Niger', zh: '尼日尔', locale: 'NE', code: 227 },
  { shoupinyin: 'N', en: 'Nigeria', zh: '尼日利亚', locale: 'NG', code: 234 },
  { shoupinyin: 'C', en: 'NorthKorea', zh: '朝鲜', locale: 'KP', code: 850 },
  { shoupinyin: 'N', en: 'Norway', zh: '挪威', locale: 'NO', code: 47 },
  { shoupinyin: 'A', en: 'Oman', zh: '阿曼', locale: 'OM', code: 968 },
  { shoupinyin: 'B', en: 'Pakistan', zh: '巴基斯坦', locale: 'PK', code: 92 },
  { shoupinyin: 'B', en: 'Panama', zh: '巴拿马', locale: 'PA', code: 507 },
  { shoupinyin: 'B', en: 'PapuaNewCuinea', zh: '巴布亚新几内亚', locale: 'PG', code: 675 },
  { shoupinyin: 'B', en: 'Paraguay', zh: '巴拉圭', locale: 'PY', code: 595 },
  { shoupinyin: 'M', en: 'Peru', zh: '秘鲁', locale: 'PE', code: 51 },
  { shoupinyin: 'F', en: 'Philippines', zh: '菲律宾', locale: 'PH', code: 63 },
  { shoupinyin: 'B', en: 'Poland', zh: '波兰', locale: 'PL', code: 48 },
  { shoupinyin: 'F', en: 'FrenchPolynesia', zh: '法属玻利尼西亚', locale: 'PF', code: 689 },
  { shoupinyin: 'P', en: 'Portugal', zh: '葡萄牙', locale: 'PT', code: 351 },
  { shoupinyin: 'B', en: 'PuertoRico', zh: '波多黎各', locale: 'PR', code: 1787 },
  { shoupinyin: 'K', en: 'Qatar', zh: '卡塔尔', locale: 'QA', code: 974 },
  { shoupinyin: 'L', en: 'Romania', zh: '罗马尼亚', locale: 'RO', code: 40 },
  { shoupinyin: 'E', en: 'Russia', zh: '俄罗斯', locale: 'RU', code: 7 },
  { shoupinyin: 'S', en: 'SaintLueia', zh: '圣卢西亚', locale: 'LC', code: 1758 },
  { shoupinyin: 'S', en: 'SaintVincent', zh: '圣文森特岛', locale: 'VC', code: 1784 },
  { shoupinyin: 'S', en: 'SanMarino', zh: '圣马力诺', locale: 'SM', code: 378 },
  { shoupinyin: 'S', en: 'SaoTomeandPrincipe', zh: '圣多美和普林西比', locale: 'ST', code: 239 },
  { shoupinyin: 'S', en: 'SaudiArabia', zh: '沙特阿拉伯', locale: 'SA', code: 966 },
  { shoupinyin: 'S', en: 'Senegal', zh: '塞内加尔', locale: 'SN', code: 221 },
  { shoupinyin: 'S', en: 'Seychelles', zh: '塞舌尔', locale: 'SC', code: 248 },
  { shoupinyin: 'S', en: 'SierraLeone', zh: '塞拉利昂', locale: 'SL', code: 232 },
  { shoupinyin: 'X', en: 'Singapore', zh: '新加坡', locale: 'SG', code: 65 },
  { shoupinyin: 'S', en: 'Slovakia', zh: '斯洛伐克', locale: 'SK', code: 421 },
  { shoupinyin: 'S', en: 'Slovenia', zh: '斯洛文尼亚', locale: 'SI', code: 386 },
  { shoupinyin: 'S', en: 'SolomonIs', zh: '所罗门群岛', locale: 'SB', code: 677 },
  { shoupinyin: 'S', en: 'Somali', zh: '索马里', locale: 'SO', code: 252 },
  { shoupinyin: 'N', en: 'SouthAfrica', zh: '南非', locale: 'ZA', code: 27 },
  { shoupinyin: 'X', en: 'Spain', zh: '西班牙', locale: 'ES', code: 34 },
  { shoupinyin: 'S', en: 'SriLanka', zh: '斯里兰卡', locale: 'LK', code: 94 },
  { shoupinyin: 'S', en: 'St.Lucia', zh: '圣卢西亚', locale: 'LC', code: 1758 },
  { shoupinyin: 'S', en: 'St.Vincent', zh: '圣文森特', locale: 'VC', code: 1784 },
  { shoupinyin: 'S', en: 'Sudan', zh: '苏丹', locale: 'SD', code: 249 },
  { shoupinyin: 'S', en: 'Suriname', zh: '苏里南', locale: 'SR', code: 597 },
  { shoupinyin: 'S', en: 'Swaziland', zh: '斯威士兰', locale: 'SZ', code: 268 },
  { shoupinyin: 'R', en: 'Sweden', zh: '瑞典', locale: 'SE', code: 46 },
  { shoupinyin: 'R', en: 'Switzerland', zh: '瑞士', locale: 'CH', code: 41 },
  { shoupinyin: 'X', en: 'Syria', zh: '叙利亚', locale: 'SY', code: 963 },
  { shoupinyin: 'T', en: 'Taiwan', zh: '台湾省', locale: 'TW', code: 886 },
  { shoupinyin: 'T', en: 'Tajikstan', zh: '塔吉克斯坦', locale: 'TJ', code: 992 },
  { shoupinyin: 'T', en: 'Tanzania', zh: '坦桑尼亚', locale: 'TZ', code: 255 },
  { shoupinyin: 'D', en: 'Togo', zh: '多哥', locale: 'TG', code: 228 },
  { shoupinyin: 'T', en: 'Tonga', zh: '汤加', locale: 'TO', code: 676 },
  { shoupinyin: 'T', en: 'TrinidadandTobago', zh: '特立尼达和多巴哥', locale: 'TT', code: 1809 },
  { shoupinyin: 'T', en: 'Tunisia', zh: '突尼斯', locale: 'TN', code: 216 },
  { shoupinyin: 'T', en: 'Turkey', zh: '土耳其', locale: 'TR', code: 90 },
  { shoupinyin: 'T', en: 'Turkmenistan', zh: '土库曼斯坦', locale: 'TM', code: 993 },
  { shoupinyin: 'W', en: 'Uganda', zh: '乌干达', locale: 'UG', code: 256 },
  { shoupinyin: 'W', en: 'Ukraine', zh: '乌克兰', locale: 'UA', code: 380 },
  { shoupinyin: 'A', en: 'UnitedArabEmirates', zh: '阿拉伯联合酋长国', locale: 'AE', code: 971 },
  { shoupinyin: 'Y', en: 'UnitedKiongdom', zh: '英国', locale: 'GB', code: 44 },
  { shoupinyin: 'W', en: 'Uruguay', zh: '乌拉圭', locale: 'UY', code: 598 },
  { shoupinyin: 'W', en: 'Uzbekistan', zh: '乌兹别克斯坦', locale: 'UZ', code: 233 },
  { shoupinyin: 'W', en: 'Venezuela', zh: '委内瑞拉', locale: 'VE', code: 58 },
  { shoupinyin: 'Y', en: 'Vietnam', zh: '越南', locale: 'VN', code: 84 },
  { shoupinyin: 'Y', en: 'Yemen', zh: '也门', locale: 'YE', code: 967 },
  { shoupinyin: 'N', en: 'Yugoslavia', zh: '南斯拉夫', locale: 'YU', code: 381 },
  { shoupinyin: 'J', en: 'Zimbabwe', zh: '津巴布韦', locale: 'ZW', code: 263 },
  { shoupinyi: 'Z', en: 'Zambia', zh: '赞比亚', locale: 'ZM', code: 260 },
];

// 所有国家
// export const countries = () => {
//     return datas.map(item => {
//       return item.city
//     })
//   },

//   // 国家下区号
//   export const areCode= pname => {
//     for (const item of datas) {
//       if (item.city === pname) {
//         return item.area
//       }
//     }
//     return []
//   },
