import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Toast } from 'vant';

import '@/utils/styles/index.less';

// vue自定义配置
import '@/utils/vue/index';

import { updateLocalStorageStorage } from '@/utils/common';
document.addEventListener('plusready', updateLocalStorageStorage, false);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

// html5+ App包配置
document.addEventListener('plusready', () => {
  let webview = plus.webview.currentWebview();
  let nextBack = false;
  plus.key.addEventListener('backbutton', () => {
    webview.canBack(e => {
      if (e.canBack) {
        router.back();
      } else {
        if (!nextBack) {
          nextBack = true;
          Toast({
            message: '再按一次退出应用',
            duration: 1000,
          });
          setTimeout(() => {
            nextBack = false;
          }, 1000);
        } else {
          plus.runtime.quit();
        }
      }
    });
  });
  const AppSaveImage = url => {
    let downloadImage = plus.downloader.createDownload(url, {}, (d, status) => {
      if (status === 200) {
        let filepath = plus.io.convertLocalFileSystemURL(d.filename);
        plus.gallery.save(filepath);
        Toast('保存成功');
        // 删除
        plus.io.resolveLocalFileSystemURL(
          filepath,
          entry => {
            entry.remove(
              e => {},
              e => {},
            );
          },
          e => {},
        );
      } else {
        Toast(`保存文件发生错误: ${status}`);
      }
    });
    downloadImage.start();
  };
  window.ipfshyys = {
    saveToAlbum: AppSaveImage,
  };
});
