<template>
  <div class="DialogBox">
    <van-overlay :show="show" @click="show = false">
      <div class="dialog" :style="{ width: $pxToVw(width) }">
        <div class="title" v-show="title">{{ title }}</div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  name: 'DialogBox',
  // mixins: [],
  // components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 678,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  // created () {},
  // mounted () {},
  methods: {
    init(res = true) {
      this.show = res;
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.van-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog {
  border-radius: 15px;
  background: @white;
  .title {
    border-radius: 15px 15px 0 0;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eaeaff;
    font-size: 30px;
    font-weight: bold;
    color: @title-color;
  }
}
</style>
