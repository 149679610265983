<template>
  <div class="sendCodeBox dfaic">
    <van-button plain type="info" :disabled="loading" @click="sendCode" v-if="!sendCodeTime">发送验证码</van-button>
    <template v-else>
      <van-count-down :time="sendCodeTime" @finish="sendCodeTime = ''" format="ss" />
      <i>s</i>
    </template>
  </div>
</template>
<script>
export default {
  name: 'SendCode',
  // mixins: [],
  // components: {},
  props: {
    url: {
      type: String,
      default: '',
      require,
    },
    areaCode: {
      type: Number,
      default: 86,
    },
    phone: {
      type: String,
      default: '',
    },
    noPhone: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      loading: false,
      sendCodeTime: '',
    };
  },
  // created () {},
  // mounted () {},
  methods: {
    sendCode() {
      if (this.loading === true) return;
      if (!this.phone && !this.noPhone) {
        this.$toast('手机号不能为空');
        this.error();
        return;
      }
      this.loading = true;
      this.$http('post', this.url, {
        areaCode: this.areaCode,
        phone: this.phone,
      })
        .then(res => {
          this.$toast('发送成功');
          this.sendCodeTime = 60 * 1000;
        })
        .all(() => {
          this.loading = false;
        });
    },
  },
  // watch: {},
  computed: {
    // phoneProcess() {
    //   if (this.areaCode === 86) {
    //     return this.phone;
    //   } else {
    //     return this.areaCode + this.phone;
    //   }
    // },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.sendCodeBox {
  i {
    font-size: 28px;
    line-height: 19px;
  }
}
</style>
