export default [
  {
    path: '/home',
    alias: '',
    component: () => import('@/pages/home/_layout'),
  },
  {
    path: '/home1',
    alias: '',
    component: () => import('@/pages/personal'),
  },
  // 账户
  {
    path: '/account',
    component: () => import('@/pages/account/_layout'),
    meta: {
      loginAfterAuth: true,
    },
    children: [
      // 登录
      {
        path: 'login',
        alias: '',
        component: () => import('@/pages/account/login'),
      },
      // 注册
      {
        path: 'register',
        component: () => import('@/pages/account/register'),
      },
    ],
  },
  // 奖励
  {
    path: '/award',
    component: () => import('@/pages/award/_layout'),
    children: [
      // 首页
      {
        path: '',
        component: () => import('@/pages/award/index'),
      },
      // 规则
      {
        path: 'rule',
        component: () => import('@/pages/award/rule'),
      },
    ],
  },
  // 锁仓
  {
    path: '/locked',
    component: () => import('@/pages/locked/_layout'),
    children: [
      // 首页
      {
        path: '',
        component: () => import('@/pages/locked/index'),
      },
      // 订单
      {
        path: 'order',
        component: () => import('@/pages/locked/order'),
        meta: {
          requireAuth: true,
        },
      },
      // 立即锁仓
      {
        path: 'dig',
        component: () => import('@/pages/locked/dig'),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  // 我的
  {
    path: '/personal',
    component: () => import('@/pages/personal/_layout'),
    children: [
      // 首页
      {
        path: '',
        component: () => import('@/pages/personal/index'),
      },
      // 加速包
      {
        path: 'addPower',
        component: () => import('@/pages/personal/addPower'),
      },
      // 我的积分
      {
        path: 'myAssets',
        component: () => import('@/pages/personal/myAssets/_layout'),
        meta: {
          requireAuth: true,
        },
        children: [
          // 首页
          {
            path: '',
            component: () => import('@/pages/personal/myAssets/index'),
          },
          // 资产列表
          {
            path: 'assetsList',
            component: () => import('@/pages/personal/myAssets/assetsList'),
          },
          // 充值
          {
            path: 'addCoin',
            component: () => import('@/pages/personal/myAssets/addCoin'),
          },
          // 提取
          {
            path: 'getCoin',
            component: () => import('@/pages/personal/myAssets/getCoin'),
          },
          // 划转
          {
            path: 'translateCoin',
            component: () => import('@/pages/personal/myAssets/translateCoin'),
          },
          // 提取地址
          {
            path: 'getCoinSite',
            component: () => import('@/pages/personal/myAssets/getCoinSite'),
          },
          // 充提记录
          {
            path: 'addGetRecord',
            component: () => import('@/pages/personal/myAssets/addGetRecord'),
          },
        ],
      },
      // 资产流水
      {
        path: 'moneyHistory',
        component: () => import('@/pages/personal/moneyHistory/index'),
        meta: {
          requireAuth: true,
        },
      },
      // 邀请分享
      {
        path: 'invite',
        component: () => import('@/pages/personal/invite/_layout'),
        children: [
          // 首页
          {
            path: '',
            component: () => import('@/pages/personal/invite/index'),
            meta: {
              requireAuth: true,
            },
          },
          // 邀请注册
          {
            path: 'ir',
            component: () => import('@/pages/personal/invite/inviteRegister'),
            meta: {
              loginAfterAuth: true,
            },
          },
        ],
      },
      // 账号管理
      {
        path: 'admin',
        component: () => import('@/pages/personal/admin/_layout'),
        meta: {
          requireAuth: true,
        },
        children: [
          // 首页
          {
            path: '',
            component: () => import('@/pages/personal/admin/index'),
          },
          // 实名认证
          {
            path: 'auth',
            component: () => import('@/pages/personal/admin/auth'),
          },
          // 修改登录密码
          {
            path: 'updatePassword',
            component: () => import('@/pages/personal/admin/updatePassword'),
          },
          // 修改手机号
          {
            path: 'updatePhone',
            component: () => import('@/pages/personal/admin/updatePhone'),
          },
        ],
      },
      // 设置
      {
        path: 'set',
        component: () => import('@/pages/personal/set'),
      },
    ],
  },
  // 其他
  {
    path: '/other',
    component: () => import('@/pages/other/_layout'),
    children: [
      // 关于我们
      {
        path: 'aboutUs',
        component: () => import('@/pages/other/aboutUs'),
      },
      // 用户协议
      {
        path: 'userAgreement',
        component: () => import('@/pages/other/userAgreement'),
      },
      // 公告
      {
        path: 'notice',
        component: () => import('@/pages/other/notice'),
      },
      // 详情
      {
        path: 'detail',
        component: () => import('@/pages/other/detail'),
      },
      // 下载页
      {
        path: 'download',
        component: () => import('@/pages/other/download'),
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/pages/personal'),
  },
];
