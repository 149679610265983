<template>
  <div class="NavBarBox">
    <van-nav-bar :title="title" fixed placeholder @click-left="$router.back()">
      <template #left>
        <van-icon name="arrow-left" :size="$pxToPxRatio(32)" color="#000000" v-if="leftArrow" />
      </template>
      <template #right>
        <slot name="right"></slot>
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
export default {
  name: 'NavBar',
  // mixins: [],
  // components: {},
  props: {
    leftArrow: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.NavBarBox {
  &.noBorder {
    /deep/ .van-nav-bar {
      &:after {
        display: none;
      }
    }
  }
}
</style>
