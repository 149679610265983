<template>
  <div :class="`MaskBox ${position}`" v-if="show">
    <div class="maskBg" @click="onMaskBg"></div>
    <div class="maskContext">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MaskBox',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    clickHidden: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: '',
    },
  },
  methods: {
    onMaskBg() {
      if (!this.clickHidden) return;
      this.$emit('update:show', false);
    },
  },
};
</script>
<style scoped lang="less">
.MaskBox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  .maskBg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #1c1c1e;
    opacity: 0.6;
    z-index: -1;
  }
  &.center,
  &.left,
  &.right,
  &.bottom {
    .maskContext {
      position: absolute;
      padding: 0 30px;
      width: 100%;
      z-index: 1;
    }
  }
  &.center {
    .maskContext {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.bottom {
    .maskContext {
      left: 0;
      bottom: 16px;
    }
  }
}
</style>
