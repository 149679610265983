let state = {
  defaultSite: '',
  getCoinObj: '',
  userAssetsInfo: '',
};
let mutations = {
  save(state, action) {
    for (let [key, val] of Object.entries(action)) {
      state[key] = val;
    }
  },
};
let getters = {};
let actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
