<template>
  <div :style="{ fontSize: fontSizeProcess, color, fontWeight: bold * 100 }" :class="color">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'TextBox',
  // mixins: [],
  // components: {},
  props: {
    fontSize: {
      type: Number | String,
      default: 28,
    },
    color: {
      type: String,
      default: '',
    },
    bold: {
      type: Number | String,
      default: 4,
    },
  },
  data() {
    return {};
  },
  // created () {},
  // mounted() {},
  // methods: {},
  // watch: {},
  computed: {
    fontSizeProcess() {
      return this.$pxToVw(this.fontSize);
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.title {
  color: @title-color;
}
.blackTitle {
  color: @black-title-color;
}
.intro {
  color: @intro-color;
}
.intro1 {
  color: @intro-color1;
}
.textColor2 {
  color: @text-color2;
}
.textColor1 {
  color: @text-color1;
}
.textColor4 {
  color: @text-color4;
}
.danger {
  color: @red1;
}
</style>
