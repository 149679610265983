import Vue from 'vue';

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// 有赞UI库
import {
  Button,
  Image as VanImage,
  Cell,
  CellGroup,
  Col,
  Row,
  Popup,
  Toast,
  Field,
  Form,
  Switch,
  Uploader,
  ActionSheet,
  Dialog,
  Loading,
  NoticeBar,
  Sticky,
  Overlay,
  PullRefresh,
  CountDown,
  Divider,
  Empty,
  Lazyload,
  List,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  NavBar,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  Icon,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Calendar,
  ImagePreview,
  Notify,
  Popover,
  Stepper,
} from 'vant';
Vue.use(Stepper);
Vue.use(Popover);
Vue.use(Notify);
Vue.use(ImagePreview);
Vue.use(Calendar);
Vue.use(DatetimePicker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Form);
Vue.use(Switch);
Vue.use(Uploader);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(NoticeBar);
Vue.use(Sticky);
Vue.use(Overlay);
Vue.use(PullRefresh);
Vue.use(CountDown);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(List);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tabbar);
Vue.use(TabbarItem);
