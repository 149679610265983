import axios from 'axios';
import envConfig from '@/utils/env';
import router from '@/router/index';
import { Dialog, Toast } from 'vant';
import { logout, toNumber, $localStorage } from '@/utils/common';

axios.prototype.axiosPromiseArr = [];
axios.defaults.timeout = 10000;
axios.defaults.baseURL = envConfig.url;

axios.interceptors.request.use(
  config => {
    let token = $localStorage.getItem('token');
    let tokenExpireTime = $localStorage.getItem('tokenExpireTime');
    if (token && tokenExpireTime) {
      let nowTime = new Date().getTime();
      if (nowTime > tokenExpireTime) {
        Toast('登录状态已过期, 请重新登录');
        logout();
        router.push('/account/login');
        token = '';
      }
    }
    if (token) {
      config.headers['token'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  res => {
    if (res.data.code !== 0) {
      if (res.data.code === 401) {
        Toast('登录状态已失效, 请重新登录');
        logout();
        router.push('/account/login');
      }
      if (res.data.code === 402) {
        Dialog.alert({
          message: '此账号已在别处登录，如不是本人操作，请尽快修改密码',
        }).then(() => {
          logout();
          router.push('/account/login');
        });
      }
    }
    return res.data;
  },
  error => {
    if (error.request.readyState === 4 && error.request.status === 0) {
      // 超时
      Toast('网络错误');
    }
    return Promise.reject(error);
  },
);
